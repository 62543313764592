/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
	
	/*
	font-family: 'Akaya Telivigala', cursive;
	font-family: 'Montserrat', sans-serif;
	font-family: 'Poppins', sans-serif !important;
	font-family: 'Roboto', sans-serif;
	*/

	font-family: 'Raleway', sans-serif;
	box-sizing: border-box;
}

.ng-touched:not(form) {
    border: 1px solid rgb(197, 78, 78);
}

.ng-valid:not(form) {
    border: 1px solid rgb(0, 160, 0);
}

.radius-0 {
	border-radius: 0 !important;
} 

.radius-4 {
	border-radius: 4px;
}

.bg-gaj {
	background: #921831;
	color: #fff;
}
.text-gaj {
	color: #921831 !important;
}

.fa-asterisk {
	font-size: 8px;
	font-weight: 500;
	color: red;
	top: 0;
	margin-top: -10px;
	margin-left: 8px;
}

.relative {
	position: relative;
}
.absolute {
	position: absolute;
}

.go-top-btn {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #fff;
	bottom: 0;
	right: 0;
	margin-bottom: 15px;
	margin-right: 15px;
	border-radius: 50%;
	background: #f90033;
	position: fixed;
	cursor: pointer;
	display: none;
	z-index: 10000000000;
}

/*font-weight styles*/
.fw-1 {font-weight: 100;}
.fw-2 {font-weight: 200;}
.fw-3 {font-weight: 300;}
.fw-4 {font-weight: 400;}
.fw-5 {font-weight: 500;}
.fw-6 {font-weight: 600;}
.fw-7 {font-weight: 700;}
.fw-8 {font-weight: 800;}
.fw-9 {font-weight: 900;}








.btn-gaj {
	background: #921831 !important;
	color: rgba(255,255,255,.9);
	transition: 0.3s;
	font-weight: 500;
}

.btn-gaj:hover {
	color: #fff;
}
.page-sub-title {
	font-size: 24px;
	font-weight: 500;
}

.close-btn {
	width: 34px;
	height: 34px;
	top: 0;
	right: 0;
	position: absolute;
	margin-right: 0px;
	background: #921831;
	outline: none;
	border-radius: 0 0 0 34px;
	box-shadow: none;
	text-align: center;
	transition: 0.3s;
	padding: 0 0 5px 5px;
	z-index: 10;
}
.close-btn:hover {
	background: #6b1225;
	width: 44px;
	height: 44px;
	border-radius: 0 0 0 44px;
	padding: 0 0 10px 10px;
}

.closemodalicon {
	transition: 1.5s;
}

.close-btn:hover .closemodalicon {
	transform: rotate(180deg);
}

.required-star {
	font-size: 30px;
	font-weight: 700;
	color: red;
	line-height: 25px;
}

.d-none {
	display: none !important;
}